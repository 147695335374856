<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
  getBiggestThumbnailUrl,
} from '@shopware-pwa/helpers-next';
import type { Category } from '@shopware-pwa/types';

const currentChildMenuPosition = ref<string | null>(null);
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const props = defineProps<{
  isMenuActive: boolean;
  activeNavElem: Category;
  activeChildElem: Category;
}>();
const activeChildMenu = toRef(props, 'activeChildElem');
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <Transition>
    <nav
      v-if="activeNavElem && isMenuActive"
      class="z-190 lg:min-h-auto fixed top-0 flex h-screen min-h-[600px] w-full items-center overflow-auto rounded-none bg-white"
      aria-label="Top navigation"
      role="menu"
    >
      <!-- Submenu -->
      <client-only>
        <div class="active-menu-wrapper pt-35 h-full w-full lg:w-1/2">
          <!-- Level 2 -->
          <div v-if="activeNavElem?.children?.length" class="flex">
            <!-- Nav List -->
            <div class="nav-submenu-box w-1/2">
              <template
                v-for="(childElement, index) in activeNavElem.children"
                :key="childElement.id"
              >
                <div
                  :class="{
                    'sm:pb-0': index !== activeNavElem.children.length - 1,
                  }"
                  class="relative grid py-2 sm:p-3 sm:px-6"
                  @click="
                    currentChildMenuPosition = childElement.id;
                    $emit('setActiveChildMenu', childElement);
                  "
                >
                  <!-- Show link or Button if child items -->
                  <span
                    v-if="childElement?.children?.length > 0"
                    class="!hover:text-grey flex cursor-pointer justify-between rounded-lg p-2"
                  >
                    <div
                      class="flex flex-grow flex-col"
                      :class="{
                        'max-w-200px md:max-w-300px': !!childElement.media,
                      }"
                    >
                      <p class="text-2xl font-medium">
                        {{ getTranslatedProperty(childElement, 'name') }}
                      </p>
                      <!-- <p v-if="getTranslatedProperty(childElement, 'description')" class="mt-1 text-sm text-gray-500" v-html="getTranslatedProperty(childElement, 'description')" /> -->
                    </div>
                  </span>
                  <NuxtLink
                    v-else
                    :to="formatLink(getCategoryRoute(childElement))"
                    :target="
                      childElement.externalLink || childElement.linkNewTab
                        ? '_blank'
                        : ''
                    "
                    class="!hover:text-grey flex justify-between rounded-lg p-2"
                    @click="$emit('closeMenu')"
                  >
                    <div
                      class="flex flex-grow flex-col"
                      :class="{
                        'max-w-200px md:max-w-300px': !!childElement.media,
                      }"
                    >
                      <p class="text-2xl font-medium">
                        {{ getTranslatedProperty(childElement, 'name') }}
                      </p>
                      <p
                        v-if="
                          getTranslatedProperty(childElement, 'description')
                        "
                        class="mt-1 text-sm"
                        v-html="
                          getTranslatedProperty(childElement, 'description')
                        "
                      />
                    </div>
                  </NuxtLink>

                  <!-- Level 3 -->
                  <div
                    v-if="
                      currentChildMenuPosition === childElement.id &&
                      childElement?.children?.length &&
                      isMenuActive
                    "
                    class=""
                  >
                    <div class="">
                      <template
                        v-for="(
                          grandChildElement, index
                        ) in childElement.children"
                        :key="grandChildElement.id"
                      >
                        <div
                          :class="{
                            'sm:pb-0':
                              index !== childElement.children.length - 1,
                            'sm:pt-1': index === 0,
                          }"
                          class="relative grid gap-6 bg-white px-3 py-2 sm:gap-6 sm:p-3 sm:px-2"
                        >
                          <NuxtLink
                            :to="
                              formatLink(getCategoryRoute(grandChildElement))
                            "
                            :target="
                              grandChildElement.externalLink ||
                              grandChildElement.linkNewTab
                                ? '_blank'
                                : ''
                            "
                            class="!hover:text-grey flex justify-between rounded-lg p-2 px-0"
                          >
                            <div
                              class="flex flex-grow flex-col"
                              :class="{
                                'max-w-200px md:max-w-300px':
                                  !!grandChildElement.media,
                              }"
                              @click="$emit('closeMenu')"
                            >
                              <p class="text-xl font-medium">
                                {{
                                  getTranslatedProperty(
                                    grandChildElement,
                                    'name',
                                  )
                                }}
                              </p>
                              <p
                                v-if="
                                  getTranslatedProperty(
                                    grandChildElement,
                                    'description',
                                  )
                                "
                                class="mt-1 text-sm"
                                v-html="
                                  getTranslatedProperty(
                                    grandChildElement,
                                    'description',
                                  )
                                "
                              />
                            </div>
                            <!-- <div v-if="grandChildElement.media" class="flex">
                            <NuxtImg :src="getSmallestThumbnailUrl(grandChildElement.media)" class="object-scale-down h-48 w-px-200 rounded-md" alt="Category image" />
                          </div> -->
                          </NuxtLink>
                        </div>
                      </template>
                      <!-- <div class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                      <div class="flow-root" v-html="getTranslatedProperty(childElement, 'description')" />
                    </div> -->
                    </div>
                  </div>
                </div>
              </template>
              <!-- <div class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
              <div class="flow-root" v-html="getTranslatedProperty(navigationElement, 'description')" />
            </div> -->
            </div>
            <!-- Right Col -->
            <div class="nav-promo-box w-1/2 pl-4 pr-4 pt-6">
              <div
                v-if="activeChildMenu && activeChildMenu?.media"
                class="block"
              >
                <NuxtImg
                  :src="
                    getBiggestThumbnailUrl(activeChildMenu.media) ||
                    activeChildMenu.media?.url
                  "
                  class="object-fit w-full"
                  alt="Category image"
                />
                <div class="actions mt-4">
                  <div
                    class="cms-rendered-html"
                    v-html="
                      getTranslatedProperty(activeChildMenu, 'description')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </client-only>
    </nav>
  </Transition>
</template>
<style lang="scss">
// .v-enter-active,
// .v-leave-active {
//   transition: all .2s ease-in;
// }

// .v-enter-from,
// .v-leave-to {
//   height: 0px;
//   min-height: 0px;
//   top: 1.5rem;
//   border-radius: 5rem;
//   overflow: hidden;
// }
.active-menu-wrapper a.router-link-active {
  color: #999999;
}
</style>
